import React from 'react';
import EventList from '../components/EventList';

const EventPage = () => {
    
        return (
            <div data-testid="eventPage">
              <EventList />
            </div>
          );
    }


export default EventPage;