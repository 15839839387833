import React from 'react';
import MatchPlayList from '../components/MatchPlayList';

const MatchPlayPage = () => {
        return (
            <div data-testid="MatchPlayPage">
              <MatchPlayList />
            </div>
          );
    }


export default MatchPlayPage;