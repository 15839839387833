import React from 'react';
import UserList from '../components/UserList';

const MemberPage = () => {
        return (
            <div data-testid="memberPage">
              <UserList />
            </div>
          );
    }


export default MemberPage;