import React from 'react';
import TournamentList from '../components/TournamentList';

const TournamentsPage = () => {
    
        return (
            <div data-testid="tournamentPage">
              <TournamentList />
            </div>
          );
    }


export default TournamentsPage;