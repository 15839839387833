import React from 'react';
import CourseList from '../components/CourseList';

const CoursePage = () => {
        return (
            <div data-testid="coursePage">
              <CourseList />
            </div>
          );
}

export default CoursePage;